import { BrowserLanguage, LocaleCode } from '@groma-dev/scaffold';
import { AppConfigInterface } from './AppConfigInterface';
import { locales } from './locales';

export const AppConfig: AppConfigInterface = {
  Settings: {
    Authentication: {
      enabled: false,
      cookie: {
        name: process.env.AUTH_COOKIE_NAME ?? '',
        domain: process.env.AUTH_COOKIE_DOMAIN ?? '',
      },
    },
    Domains: {
      holdings: {
        baseUrl: process.env.HOLDINGS_BASE_URL ?? '',
      },
      id: {
        baseUrl: process.env.ID_BASE_URL ?? '',
      },
      investors: {
        baseUrl: process.env.INVESTORS_BASE_URL ?? '',
      },
      landing: {
        baseUrl: process.env.LANDING_BASE_URL ?? '',
      },
      rentals: {
        baseUrl: process.env.RENTALS_BASE_URL ?? '',
      },
      tenants: {
        baseUrl: process.env.TENANTS_BASE_URL ?? '',
      },
    },
    Localization: {
      defaultLocale: LocaleCode.en_us,
      browserLanguage: BrowserLanguage.en,
      locales,
    },
    Metrics: {
      Firebase: {
        enabled: process.env.FIREBASE_ENABLED === 'true',
        config: {
          apiKey: process.env.FIREBASE_API_KEY ?? '',
          authDomain: process.env.FIREBASE_AUTH_DOMAIN ?? '',
          projectId: process.env.FIREBASE_PROJECT_ID ?? '',
          storageBucket: process.env.FIREBASE_STORAGE_BUCKET ?? '',
          messagingSenderId: process.env.FIREBASE_SENDER_ID ?? '',
          appId: process.env.FIREBASE_APP_ID ?? '',
          measurementId: process.env.FIREBASE_MEASUREMENT_ID ?? '',
        },
      },
      NewRelic: {
        enabled: process.env.NEWRELIC_ENABLED === 'true',
        config: {
          accountID: process.env.NEWRELIC_ACCOUNT_ID ?? '',
          trustKey: process.env.NEWRELIC_TRUST_ID ?? '',
          agentID: process.env.NEWRELIC_AGENT_ID ?? '',
          licenseKey: process.env.NEWRELIC_LICENSE_KEY ?? '',
          applicationID: process.env.NEWRELIC_APPLICATION_ID ?? '',
        },
      },
    },
    Routing: {
      searchParams: {
        allowList: [
          'kiosk', // Kiosk param indicates that the main kiosk display is rendering the application
        ],
      },
    },
    Seo: {
      enabled: process.env.SEO_ENABLED === 'true',
    },
    ServiceWorker: {
      // TODO: Disabled for SEO issues. Look to add back in the future
      enabled: process.env.SERVICE_WORKER_ENABLED === 'true',
      options: {
        forceUpdateRefresh: false,
      },
    },
    Server: {
      apiVersion: 'v1',
      baseUrl: process.env.BASE_API_URL ?? '',
      timeout: 30000,
      endpoints: {
        api: () => `${AppConfig.Settings.Server.baseUrl}`,
      },
      passwordProtection: {
        cookieName: process.env.APP_PROTECTION_COOKIE ?? '',
        enabled: process.env.APP_PROTECTION_ENABLED === 'true',
        password: process.env.APP_PROTECTION_PASSWORD ?? '',
        protectionKey: process.env.APP_PROTECTION_KEY ?? '',
      },
    },
  },
  Pages: {
    About: {
      options: {
        baseUrl: process.env.ASSETS_BASE_URL ?? '',
      },
    },
    Careers: {
      baseUrl: process.env.GREENHOUSE_BASE_URL ?? '',
    },
    FAQ: {
      options: {
        baseUrl: process.env.ASSETS_BASE_URL ?? '',
      },
    },
    Press: {
      enabled: true,
      options: {
        baseUrl: process.env.ASSETS_BASE_URL ?? '',
      },
    },
    Properties: {
      pageSize: 100,
    },
    Realty: {
      contactUs: {
        enabled: false,
      },
      options: {
        baseUrl: process.env.ASSETS_BASE_URL ?? '',
      },
    },
    Technology: {
      images: {
        enabled: false,
      },
    },
    Vision: {
      images: {
        enabled: false,
      },
    },
  },
  Components: {
    ContactForm: {},
    PartnershipsMap: {
      MapBox: {
        accessToken: 'pk.eyJ1IjoiZGV2LWdyb21hIiwiYSI6ImNsMjZqY3E1ejAwemQzanJvcnJicXhyYWgifQ.37yea6c6LwBKnoZOrpEb6A',
        style: 'mapbox://styles/dev-groma/clmgm6s4y03o101p9htna4r73',
        defaultConfig: {
          zoom: 3,
        },
        options: {
          fitMapMarkers: {
            enabled: true,
            duration: 800,
          },
        },
      },
    },
    PropertyList: {
      renting: {
        enabled: true,
      },
    },
    PropertyMap: {
      MapBox: {
        accessToken: process.env.MAPBOX_TOKEN,
        style: process.env.MAPBOX_STYLE_URL,
        defaultConfig: {
          lat: 42.361,
          lng: -71.064,
          zoom: 11,
        },
        options: {
          fitMapMarkers: {
            enabled: true,
            duration: 800,
          },
        },
      },
    },
    ScriptInjector: {
      enabled: process.env.SCRIPT_INJECTOR_ENABLED === 'true',
    },
  },
};
