import React from 'react';
import { observer } from 'mobx-react-lite';
import { MenuSection, NavItem } from '@groma-dev/navigation';
import { ArrowRightIcon, classNames, getTestIdAttribute } from '@groma-dev/blueprint';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { HeaderMenu } from '../Header';
import { AppConfig } from '../../../../core/AppConfig';
import styles from './Menu.module.scss';
import invest from './assets/invest.svg';
import rent from './assets/rent.svg';
import partner from './assets/partner.svg';
import about from './assets/about.svg';

export interface MenuProps {
  section?: HeaderMenu;
  signOutUser?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const Menu = observer((props: MenuProps) => {
  const { section } = props;
  const { formatMessage } = useIntl();
  const { Domains } = AppConfig.Settings;
  const { pathname } = useLocation();

  switch (section) {
    case HeaderMenu.INVEST:
      return (
        <>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.invest.heading.one',
              description: 'Header invest menu heading one text',
              defaultMessage: 'Overview',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  to="/"
                  as={Link}
                  testId="investAbout"
                  className={classNames(styles.primaryLink, pathname === '/' && styles.active)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.invest.about.primary"
                      description="Header invest menu about primary text"
                      defaultMessage="About"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.invest.about.secondary"
                    description="Header invest menu about secondary text"
                    defaultMessage="GromaREIT, the first Real Estate Innovation Trust, uses technology to improve property performance and
                  investor access."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href={`${Domains.holdings.baseUrl}`}
                  testId="investPerformance"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.invest.performance.primary"
                      description="Header invest menu performance primary text"
                      defaultMessage="Performance"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.invest.performance.secondary"
                    description="Header invest menu performance secondary text"
                    defaultMessage="Overview of the performance of the GromaREIT, including asset appreciation and distributions."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href={`${Domains.holdings.baseUrl}/properties`}
                  testId="investPortfolio"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.invest.portfolio.primary"
                      description="Header invest menu portfolio primary text"
                      defaultMessage="Portfolio"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.invest.portfolio.secondary"
                    description="Header invest menu portfolio secondary text"
                    defaultMessage="Detailed information about each property in the GromaREIT."
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.invest.heading.two',
              description: 'Header invest menu heading two text',
              defaultMessage: 'Investor Resources',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  to="/faq"
                  testId="investFAQ"
                  as={Link}
                  className={classNames(styles.secondaryLink, pathname === '/faq' && styles.active)}>
                  <FormattedMessage
                    id="header.menu.invest.faq"
                    description="Header invest menu faq text"
                    defaultMessage="FAQs"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href="https://materials.groma.com"
                  testId="investMaterials"
                  className={classNames(styles.secondaryLink, pathname === '/materials' && styles.active)}>
                  <FormattedMessage
                    id="header.menu.invest.materials"
                    description="Header invest offering materials faq text"
                    defaultMessage="Offering Materials"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href="https://share.hsforms.com/1lZcVfnqhRqmWzjvYpTdpTgd637t"
                  target="_blank"
                  testId="investInvestorRelations"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.invest.investorRelations"
                    description="Header invest menu investor relations text"
                    defaultMessage="Contact Investor Relations"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href={Domains.investors.baseUrl}
                  testId="investInvestorSignIn"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.invest.signIn"
                    description="Header invest menu investor sign in text"
                    defaultMessage="Investor Sign In"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection className={classNames(styles.section, styles.ctaContainer)}>
            <div className={classNames(styles.ctaWrapper)}>
              <img src={invest} className={classNames(styles.ctaAsset)} aria-label="visualization of a generic chart" />
              <div className={classNames(styles.ctaContentContainer)}>
                <p className={classNames(styles.ctaContent)}>
                  <FormattedMessage
                    id="header.menu.invest.cta.text"
                    description="Header invest menu cta text"
                    defaultMessage="Invest in the Groma Real Estate <i>Innovation</i> Trust (GromaREIT)"
                    values={{
                      i: chunk => <i>{chunk}</i>,
                    }}
                  />
                </p>
                <NavItem
                  href="https://republic.com/groma?utm_source=groma&utm_medium=website&utm_campaign=issuer_ref"
                  testId="investGetStarted"
                  className={classNames(styles.cta)}>
                  <FormattedMessage
                    id="header.menu.invest.cta.link"
                    description="Header invest menu cta link"
                    defaultMessage="Get Started on Republic"
                  />
                  <ArrowRightIcon />
                </NavItem>
              </div>
            </div>
          </MenuSection>
        </>
      );
    case HeaderMenu.RENT:
      return (
        <>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.rent.heading.one',
              description: 'Header rent menu heading one text',
              defaultMessage: 'Available Listings',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  href={`${Domains.rentals.baseUrl}/rentals`}
                  testId="rentListings"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.rent.listings.primary"
                      description="Header rent menu listings primary text"
                      defaultMessage="Apartments"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.rent.listings.secondary"
                    description="Header rent menu listings secondary text"
                    defaultMessage="Live in a Modern Urban Rental, professionally managed by Groma."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href="https://parking.groma.com"
                  testId="rentParking"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.rent.parking.primary"
                      description="Header rent menu parking primary text"
                      defaultMessage="Parking"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.rent.parking.secondary"
                    description="Header rent menu parking secondary text"
                    defaultMessage="Private lots, garages, and driveways throughout the city."
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.rent.heading.two',
              description: 'Header rent menu heading two text',
              defaultMessage: 'Resident Resources',
            })}>
            <div className={classNames(styles.sectionWrapper)}>
              <ul className={classNames(styles.wrapper)}>
                <li>
                  <NavItem
                    testId="rentApplication"
                    href="https://groma.managebuilding.com/Resident/rental-application/new/apply"
                    className={classNames(styles.secondaryLink)}>
                    <FormattedMessage
                      id="header.menu.rent.application"
                      description="Header rent menu application link"
                      defaultMessage="Leasing Application"
                    />
                  </NavItem>
                </li>
                <li>
                  <NavItem
                    testId="rentTenantSignIn"
                    href="https://groma.managebuilding.com/Resident/portal/login"
                    className={classNames(styles.secondaryLink)}>
                    <FormattedMessage
                      id="header.menu.rent.residentSignIn"
                      description="Header rent menu resident sign in link"
                      defaultMessage="Resident Portal Sign In"
                    />
                  </NavItem>
                </li>
              </ul>
              <div
                className={classNames(styles.wrapper, styles.sectionUtil)}
                {...getTestIdAttribute('rentUtilSection')}>
                <p>
                  <FormattedMessage
                    id="header.menu.rent.util.heading"
                    description="Header rent menu util section heading text"
                    defaultMessage="Resident 24/7 Contact"
                  />
                </p>
                <NavItem href="mailto:management@groma.com" className={classNames(styles.email)}>
                  management@groma.com
                </NavItem>
                <NavItem href="tel:18554766291" className={classNames(styles.phone)}>
                  1.855.476.6291
                </NavItem>
              </div>
            </div>
          </MenuSection>
          <MenuSection className={classNames(styles.section, styles.ctaContainer)}>
            <div className={classNames(styles.ctaWrapper)}>
              <img
                src={rent}
                className={classNames(styles.ctaAsset)}
                aria-label="visualization of a map with location markers"
              />
              <div className={classNames(styles.ctaContentContainer)}>
                <p className={classNames(styles.ctaContent)}>
                  <FormattedMessage
                    id="header.menu.rent.cta.text"
                    description="Header rent menu cta text"
                    defaultMessage="Live with Groma"
                  />
                </p>
                <NavItem
                  href={`${Domains.rentals.baseUrl}/rentals`}
                  testId="rentAvailableUnit"
                  className={classNames(styles.cta)}>
                  <FormattedMessage
                    id="header.menu.rent.cta.link"
                    description="Header rent menu cta link text"
                    defaultMessage="See Available Units"
                  />
                  <ArrowRightIcon />
                </NavItem>
              </div>
            </div>
          </MenuSection>
        </>
      );
    case HeaderMenu.PARTNER:
      return (
        <>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.partner.heading.one',
              description: 'Header partner menu heading one text',
              defaultMessage: 'Overview',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  testId="partnerLink"
                  as={Link}
                  to="/partner"
                  className={classNames(styles.primaryLink, pathname === '/partner' && styles.active)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.partner.link.primary"
                      description="Header partner menu link primary text"
                      defaultMessage="Partner with Groma"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.partner.link.secondary"
                    description="Header partner menu link secondary text"
                    defaultMessage="Leverage our MUR (Modern Urban Rental) platform in customizable ways to achieve your growth, income or
                  tax structuring goals."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href="https://upreit.groma.com"
                  testId="partnerUPREIT"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.partner.upreit.primary"
                      description="Header partner menu upreit primary text"
                      defaultMessage="UPREIT with Groma"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.partner.upreit.secondary"
                    description="Header partner menu upreit secondary text"
                    defaultMessage="A tax-advantaged to sell your property for a combination of cash and tax-deferred GromaREIT shares."
                  />
                </NavItem>
              </li>
              {/* TODO: Add back when MUR site is live */}
              {/* <li> */}
              {/*  <NavItem href="https://www.groma.com" testId="partnerMUR" className={classNames(styles.primaryLink)}> */}
              {/*    <span> */}
              {/*      <FormattedMessage */}
              {/*        id="header.menu.partner.mur.primary" */}
              {/*        description="Header partner menu mur primary text" */}
              {/*        defaultMessage="MUR Asset Class" */}
              {/*      /> */}
              {/*    </span> */}
              {/*    <FormattedMessage */}
              {/*      id="header.menu.partner.mur.secondary" */}
              {/*      description="Header partner menu mur secondary text" */}
              {/*      defaultMessage="Groma’s platform enables us to efficiently acquire, modernize, and operate 2-20 unit buildings, */}
              {/*    unlocking this new asset class." */}
              {/*    /> */}
              {/*  </NavItem> */}
              {/* </li> */}
            </ul>
          </MenuSection>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.partner.heading.two',
              description: 'Header partner menu heading two text',
              defaultMessage: 'Resources',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  href={`${Domains.holdings.baseUrl}`}
                  testId="partnerPerformance"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.partner.performance"
                    description="Header partner menu performance link text"
                    defaultMessage="GromaREIT Performance Metrics"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href={`${Domains.holdings.baseUrl}/properties`}
                  testId="partnerPortfolio"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.partner.portfolio"
                    description="Header partner menu portfolio link text"
                    defaultMessage="GromaREIT Portfolio"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  testId="partnerFundOne"
                  href="https://storage.googleapis.com/groma-public-assets/documents/Groma_Boston_Fund_1_Track_Record.pdf"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.partner.fundOne"
                    description="Header partner menu fundOne link text"
                    defaultMessage="Boston Fund I Track Record"
                  />
                </NavItem>
              </li>
              {/* TODO: Update when available */}
              <li>
                <NavItem
                  href="https://share.hsforms.com/1lZcVfnqhRqmWzjvYpTdpTgd637t"
                  testId="partnerContact"
                  target="_blank"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.partner.contact"
                    description="Header partner menu contact link text"
                    defaultMessage="Contact Investor Relations"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection className={classNames(styles.section, styles.ctaContainer)}>
            <div className={classNames(styles.ctaWrapper)}>
              <img
                src={partner}
                className={classNames(styles.ctaAsset)}
                aria-label="visualization of a Modern urban rentals"
              />
              <div className={classNames(styles.ctaContentContainer)}>
                <p className={classNames(styles.ctaContent)}>
                  <FormattedMessage
                    id="header.menu.partner.cta.text"
                    description="Header partner menu cta text"
                    defaultMessage="Partner with Groma"
                  />
                </p>
                <NavItem
                  href="https://calendly.com/christina-dorobek"
                  testId="partnerBookCall"
                  className={classNames(styles.cta)}>
                  <FormattedMessage
                    id="header.menu.partner.cta.link"
                    description="Header partner menu cta link text"
                    defaultMessage="Book a Call"
                  />
                  <ArrowRightIcon />
                </NavItem>
              </div>
            </div>
          </MenuSection>
        </>
      );
    case HeaderMenu.SELL:
      return (
        <>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.sell.heading.one',
              description: 'Header sell menu heading one text',
              defaultMessage: 'For Property Owners',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem testId="sellLink" href="https://sell.groma.com" className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.sell.sellyourproperty.primary"
                      description="Header about menu link primary text"
                      defaultMessage="Sell Your Property"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.sell.sellyourproperty.secondary"
                    description="Header sell menu sell property secondary text"
                    defaultMessage="Groma is an active buyer of 2-20 unit buildings. By selling direct, you get a competitive price and save time and money."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  testId="sellLink"
                  href="https://www.sell.groma.com/offer"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.sell.beginoffer.primary"
                      description="Header about menu link primary text"
                      defaultMessage="Begin with an Offer"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.sell.beginoffer.secondary"
                    description="Header sell menu sell property secondary text"
                    defaultMessage="Contact us to have a no-obligation discussion about selling your property to Groma."
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.sell.heading.two',
              description: 'Header sell menu heading two text',
              defaultMessage: 'For Brokers',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem testId="sellLink" href="https://agents.groma.com" className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.sell.clientproperty.primary"
                      description="Header about menu link primary text"
                      defaultMessage="Sell Your Clients' Property"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.sell.clientproperty.secondary"
                    description="Header sell menu sell property secondary text"
                    defaultMessage="Groma is the experienced buyer you’ll be happy you brought to your seller. Fast closes, fair prices, 1031-friendly."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  testId="sellLink"
                  href="https://agents.groma.com/contact"
                  className={classNames(styles.primaryLink)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.sell.brokercontact.primary"
                      description="Header about menu link primary text"
                      defaultMessage="Broker Contact"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.sell.brokercontact.secondary"
                    description="Header sell menu sell property secondary text"
                    defaultMessage="Get in touch! We are active buyers and happy to look at most deals and provide fast feedback."
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection className={classNames(styles.section, styles.ctaContainer)}>
            <div className={classNames(styles.ctaWrapper)}>
              <img
                src={partner}
                className={classNames(styles.ctaAsset)}
                aria-label="visualization of Boston skyline with houses"
              />
              <div className={classNames(styles.ctaContentContainer)}>
                <p className={classNames(styles.ctaContent)}>
                  <FormattedMessage
                    id="header.menu.sell.cta.text"
                    description="Header about menu cta text"
                    defaultMessage="Sell with Groma"
                  />
                </p>
              </div>
            </div>
          </MenuSection>
        </>
      );
    case HeaderMenu.ABOUT:
      return (
        <>
          <MenuSection
            className={classNames(styles.section)}
            heading={formatMessage({
              id: 'header.menu.about.heading.one',
              description: 'Header about menu heading one text',
              defaultMessage: 'Company',
            })}>
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  testId="aboutLink"
                  as={Link}
                  to="/about"
                  className={classNames(styles.primaryLink, pathname === '/about' && styles.active)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.about.link.primary"
                      description="Header about menu link primary text"
                      defaultMessage="About"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.about.link.secondary"
                    description="Header about menu link secondary text"
                    defaultMessage="Groma’s vision and our team of real estate, logistics, property operations and technology experts."
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  testId="aboutCareers"
                  as={Link}
                  to="/careers"
                  className={classNames(styles.primaryLink, pathname === '/careers' && styles.active)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.about.careers.primary"
                      description="Header about menu careers primary text"
                      defaultMessage="Careers"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.about.careers.secondary"
                    description="Header about menu careers secondary text"
                    defaultMessage="Interested in joining a fast-growing startup focused on building a great business and doing a lot of good? Lets talk!"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  testId="aboutContact"
                  as={Link}
                  to="/contact"
                  className={classNames(styles.primaryLink, pathname === '/contact' && styles.active)}>
                  <span>
                    <FormattedMessage
                      id="header.menu.about.contact.primary"
                      description="Header about menu contact primary text"
                      defaultMessage="Contact"
                    />
                  </span>
                  <FormattedMessage
                    id="header.menu.about.contact.secondary"
                    description="Header about menu contact secondary text"
                    defaultMessage="Get in touch with the Groma team."
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection className={classNames(styles.section)} heading="Learn">
            <ul className={classNames(styles.wrapper)}>
              <li>
                <NavItem
                  testId="aboutPress"
                  as={Link}
                  to="/press"
                  className={classNames(styles.secondaryLink, pathname === '/press' && styles.active)}>
                  <FormattedMessage
                    id="header.menu.about.press"
                    description="Header about menu press link text"
                    defaultMessage="Press"
                  />
                </NavItem>
              </li>
              <li>
                <NavItem
                  href="https://groma.substack.com/"
                  testId="aboutBlog"
                  className={classNames(styles.secondaryLink)}>
                  <FormattedMessage
                    id="header.menu.about.blog"
                    description="Header about menu blog link text"
                    defaultMessage="Blog"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection className={classNames(styles.section, styles.ctaContainer)}>
            <div className={classNames(styles.ctaWrapper)}>
              <img
                src={about}
                className={classNames(styles.ctaAsset)}
                aria-label="visualization of Boston skyline with houses"
              />
              <div className={classNames(styles.ctaContentContainer)}>
                <p className={classNames(styles.ctaContent)}>
                  <FormattedMessage
                    id="header.menu.about.cta.text"
                    description="Header about menu cta text"
                    defaultMessage="Learn about Groma"
                  />
                </p>
              </div>
            </div>
          </MenuSection>
        </>
      );
    default:
      return (
        <div className={classNames(styles.accountContainer)}>
          <NavItem
            href={Domains.investors.baseUrl}
            testId="accountInvestorPortal"
            className={classNames(styles.menuItem, styles.link)}>
            <FormattedMessage
              id="header.menu.account.investor.link"
              description="Header about menu account investor link text"
              defaultMessage="Investor Portal"
            />
          </NavItem>
          <NavItem
            testId="accountTenantPortal"
            href="https://groma.managebuilding.com/Resident/portal/login"
            className={classNames(styles.menuItem, styles.link)}>
            <FormattedMessage
              id="header.menu.account.tenant.link"
              description="Header about menu account resident center link text"
              defaultMessage="Resident Center"
            />
          </NavItem>
        </div>
      );
  }
});
