import React, { ReactNode } from 'react';
import { Footer as F, MenuSection, NavItem, Toolbar } from '@groma-dev/navigation';
import { observer } from 'mobx-react-lite';
import { classNames, Divider, FullLightLogo, getTestIdAttribute } from '@groma-dev/blueprint';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../../core/routes';
import { AppConfig } from '../../../core/AppConfig';
import styles from './Footer.module.scss';
import location from './assets/location.svg';
import phone from './assets/phone.svg';
import email from './assets/email.svg';
import Footnotes from './footnotes/Footnotes';

export interface FooterProps {}

const Footer = observer(() => {
  const { pathname } = useLocation();
  const { Domains } = AppConfig.Settings;
  const auxFooterRoutes = [AppRoutes.Home, AppRoutes.Partnerships];

  return (
    <F testId="footer" className={classNames(styles.footer)}>
      <Toolbar className={classNames(styles.toolbar, styles.primary)}>
        <nav
          aria-label="footer location container"
          className={classNames(styles.section, styles.location)}
          {...getTestIdAttribute('footerLocation')}>
          <FullLightLogo />
          <ul className={classNames(styles.locationDetails)}>
            <li className={classNames(styles.locationItem)}>
              <NavItem href="https://maps.app.goo.gl/XztDS1AEiRMEEMxb6">
                <img src={location} alt="location marker" />
                <FormattedMessage
                  id="footer.location.address"
                  description="Footer location address item"
                  defaultMessage="31 New Chardon St, Boston, MA 02114"
                />
              </NavItem>
            </li>
            <li className={classNames(styles.locationItem)}>
              <NavItem href="tel:8554766291">
                <img src={phone} alt="phone marker" />
                <FormattedMessage
                  id="footer.location.phone"
                  description="Footer location phone item"
                  defaultMessage="(855) 476-6291"
                />
              </NavItem>
            </li>
            <li className={classNames(styles.locationItem)}>
              <NavItem href="mailto:hello@groma.com">
                <img src={email} alt="email marker" />
                <FormattedMessage
                  id="footer.location.email"
                  description="Footer location email item"
                  defaultMessage="hello@groma.com"
                />
              </NavItem>
            </li>
          </ul>
          <div className={classNames(styles.copyrightContainer)}>
            <p>
              <FormattedMessage
                id="footer.copyright"
                defaultMessage="© {year} Groma all rights reserved."
                description="Footer copyright content"
                values={{ year: new Date().getFullYear() }}
              />
            </p>
            <p>
              <NavItem href="/privacy">
                <FormattedMessage
                  id="footer.copyright.privacy"
                  description="Footer copyright privacy"
                  defaultMessage="Privacy Policy"
                />
              </NavItem>
              <NavItem href="/terms-of-use">
                <FormattedMessage
                  id="footer.copyright.termsOfUse"
                  description="Footer copyright privacy"
                  defaultMessage="Terms of Use"
                />
              </NavItem>
            </p>
          </div>
        </nav>
        <nav
          aria-label="footer links container"
          className={classNames(styles.section, styles.linksContainer)}
          {...getTestIdAttribute('footerLinks')}>
          <MenuSection heading="About" testId="footerLinksSection">
            <ul>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/about">
                  <FormattedMessage
                    id="footer.section.about.company"
                    description="Footer about section company link"
                    defaultMessage="Company"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/careers">
                  <FormattedMessage
                    id="footer.section.about.careers"
                    description="Footer about section careers link"
                    defaultMessage="Careers"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/realty">
                  <FormattedMessage
                    id="footer.section.about.realty"
                    description="Footer about section realty link"
                    defaultMessage="Groma Realty"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection heading="Rentals" testId="footerLinksSection">
            <ul>
              <li className={classNames(styles.linkItem)}>
                <NavItem href={`${Domains.rentals.baseUrl}/rentals`}>
                  <FormattedMessage
                    id="footer.section.rentals.apartments"
                    description="Footer rentals section apartments link"
                    defaultMessage="Available Apartments"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://parking.groma.com">
                  <FormattedMessage
                    id="footer.section.rentals.parking"
                    description="Footer rentals section parking link"
                    defaultMessage="Available Parking"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://groma.managebuilding.com/Resident/rental-application/new/apply">
                  <FormattedMessage
                    id="footer.section.rentals.application"
                    description="Footer rentals section application link"
                    defaultMessage="Rental Application"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://groma.managebuilding.com/Resident/portal/login">
                  <FormattedMessage
                    id="footer.section.rentals.signIn"
                    description="Footer rentals section resident sign in link"
                    defaultMessage="Resident Sign In"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection heading="Invest" testId="footerLinksSection">
            <ul>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/">
                  <FormattedMessage
                    id="footer.section.invest.gromaREIT"
                    description="Footer invest section gromaREIT link"
                    defaultMessage="About the GromaREIT"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href={Domains.holdings.baseUrl}>
                  <FormattedMessage
                    id="footer.section.invest.performance"
                    description="Footer invest section performance link"
                    defaultMessage="Performance Metrics"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://holdings.groma.com/properties">
                  <FormattedMessage
                    id="footer.section.invest.portfolio"
                    description="Footer invest section portfolio link"
                    defaultMessage="Portfolio"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://materials.groma.com">
                  <FormattedMessage
                    id="footer.section.invest.offeringMaterials"
                    description="Footer invest section offering materials link"
                    defaultMessage="Offering Materials"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href={Domains.investors.baseUrl}>
                  <FormattedMessage
                    id="footer.section.invest.investorPortal"
                    description="Footer invest section investorPortal link"
                    defaultMessage="Investor Portal"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/partner">
                  <FormattedMessage
                    id="footer.section.invest.partner"
                    description="Footer invest section partner link"
                    defaultMessage="Partner"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/faq">
                  <FormattedMessage
                    id="footer.section.invest.faq"
                    description="Footer invest section faq link"
                    defaultMessage="FAQ"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
          <MenuSection heading="Connect" testId="footerLinksSection">
            <ul>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/press">
                  <FormattedMessage
                    id="footer.section.contact.press"
                    description="Footer contact section press link"
                    defaultMessage="Press"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://groma.substack.com/">
                  <FormattedMessage
                    id="footer.section.contact.blog"
                    description="Footer contact section blog link"
                    defaultMessage="Blog"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="/contact">
                  <FormattedMessage
                    id="footer.section.contact.link"
                    description="Footer contact section contact link"
                    defaultMessage="Contact"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://twitter.com/GromaCorp">
                  <FormattedMessage
                    id="footer.section.contact.twitter"
                    description="Footer contact section twitter link"
                    defaultMessage="Twitter (X)"
                  />
                </NavItem>
              </li>
              <li className={classNames(styles.linkItem)}>
                <NavItem href="https://linkedin.com/company/gromabiz/mycompany/">
                  <FormattedMessage
                    id="footer.section.contact.linkedin"
                    description="Footer contact section linkedin link"
                    defaultMessage="LinkedIn"
                  />
                </NavItem>
              </li>
            </ul>
          </MenuSection>
        </nav>
      </Toolbar>
      <Divider color="var(--primary300)" />
      {auxFooterRoutes.includes(pathname) && (
        <>
          <Footnotes />
          <Divider color="var(--primary300)" />
        </>
      )}
      <Toolbar className={classNames(styles.toolbar)} testId="legalFooter">
        <p className={classNames(styles.legalFooter)}>
          <FormattedMessage
            id="footer.legal.content"
            description="Legal Footer content"
            defaultMessage={
              /* eslint-disable max-len */
              'Groma Advisor, LLC (“Groma Advisor”), a wholly-owned subsidiary of GromaCorp, Inc., the sponsor of Groma NAV REIT, Inc. (“GromaREIT”), serves as the advisor of Groma NAV REIT Inc. (“GromaREIT”) and operates the groma.com website (the "Site") and is not a broker-dealer or investment advisor. All securities related activity is conducted through the direct issuer exemption or our broker dealer as described in the relevant offering materials.{br}{br}' +
              'You should speak with your financial advisor, accountant and/or attorney when evaluating any offering. While this site may provide information about our offering, neither GromaCorp nor Groma Advisor makes any recommendations or provides advice to you about investments, and no communication, through this website or in any other medium, should be construed as a recommendation for your financial decisions. The Site may make forward-looking statements that can be identified by the use of words such as "will,” “may,” “should,” “intend,” “believe,” “expect,” “could,” “target,” “estimates,” “projects” or other comparable terminology. Statements concerning projections, future performance, cash flows and any other guidance on present or future periods constitute forward-looking statements. Forward-looking statements involve significant risks and uncertainties and you should not unduly rely on these statements. You should be aware that a number of important factors, including but not limited to, the factors in our private placement memorandum, could cause our actual results to differ materially from those in the forward-looking statements.{br}{br}' +
              'GromaREIT is conducting an offering pursuant to Regulation D. The private placement memorandum for our Regulation D offering is available for accredited investors to review. Past performance is no guarantee of future results. Investments such as one in the GromaREIT are speculative and involve substantial risks to consider before investing, outlined in the respective offering materials and including, but not limited to, illiquidity, lack of diversification and complete loss of capital.{br}{br}' +
              'Key risks include, but are not limited to, limited operating history, limited diversification, risk of asset damage or theft and lack of voting rights. Also, the adverse economic effects of the COVID-19 pandemic remain unknown and could materially impact this investment. An investment in the GromaREIT constitutes only an investment in the portfolio of properties in the GromaREIT and not in GromaCorp or Groma Advisor. Investors should carefully review the risks located in the offering materials for a more comprehensive discussion of risk.{br}{br}' +
              'Investment overviews contained herein contain summaries of the purpose and the principal business terms of the investment opportunities. Such summaries are intended for informational purposes only and do not purport to be complete, and each is qualified in its entirety by reference to the more-detailed discussions contained in the private placement memorandum. Any references to a potential Regulation Crowdfunding offering until such time as the relevant filings have been made are to be considered communications under Rule 206 as part of our “testing the waters” efforts. For the avoidance of doubt, no Regulation Crowdfunding offering is currently available for Groma NAV REIT Inc. shares and (1) no money or other consideration is currently being solicited, and if any is sent in response to any communications will not be accepted (2) no offer to buy the securities will be accepted and no part of the purchase price can be received until our offering statement is filed and even then only through an intermediary’s platform and (3) any expression or indication of interest, such as signing up for a newsletter or asking questions, involves no obligation or commitment of any kind from either party.{br}{br}' +
              'GromaREIT does not offer refunds after an investment has been made. Please review the relevant offering materials and subscription documentation for more information.{br}{br}' +
              'GromaREIT intends to offer limited liquidity via redemptions at net asset value quarterly, after a one year lockup for each investment. GromaREIT has not yet begun such redemption offering and there is no guarantee that GromaREIT will be able to do so in the future.{br}{br}' +
              'Even then, redemptions are expected to be limited at no greater than 5% of the total net asset value of the GromaREIT in any given quarter. If there are redemption requests above that limit, you may not be able to redeem your whole investment on your desired timeframe. Additionally, an active trading market for any series of GromaREIT shares may not develop or be sustained. If an active public trading market for GromaREIT shares does not develop or is not sustained, it may be difficult or impossible for you to resell your interests to third parties at any price. Even if an active market does develop, the market price could decline below the amount you paid for your interests. There is no assurance that the Groma platform will provide an active market for resales of GromaREIT shares. Further, without the Groma platform, it may be difficult or impossible for you to dispose of your interests. If the market develops for GromaREIT shares, the market price of such interests could fluctuate significantly for many reasons, including reasons unrelated to performance, the underlying assets or any series, such as reports by industry analysts, investor perceptions or announcements by competitors regarding their own performance, as well as general economic and industry conditions.{br}{br}' +
              'The GromaREIT uses blockchain as a data storage layer for certain functionality. This includes producing digital records of title and deed to properties on chain, as a duplication of the records in traditional land records, and maintaining a record of share ownership in a blockchain database, as a duplication of the records stored with our transfer agent. The GromaREIT, and the shares within it which are sometimes referred to as GromaCoins, are security instruments and governed by the regulations and laws applicable to such securities, most notably the rules and regulations of the Securities and Exchange Commission of the United States of America and other regulatory bodies as appropriate.{br}{br}' +
              'For a complete list of risk factors and disclaimer information, please refer to our <a>offering materials</a>.'
              /* eslint-enable max-len */
            }
            values={{
              br: <br />,
              a: (chunk: ReactNode) => (
                <NavItem href="https://materials.groma.com" className={classNames(styles.footerLink)}>
                  {chunk}
                </NavItem>
              ),
            }}
          />
        </p>
      </Toolbar>
    </F>
  );
});

export default Footer;
